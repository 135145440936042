import React from 'react';
import { Route, IndexRoute } from 'react-router';
import logo from './logo.svg';
import './App.css';
import {Link} from 'react-router-dom';

function Footer() {
  return (
      <footer>
        <div className="links d-flex">
          <ul className="list-group list-group-horizontal-lg list-inline mx-auto justify-content-center">
            <Link to="/">
              <li className="list-group-item">home</li>
            </Link>
            <Link to="/privacy-policy">
              <li className="list-group-item">privacy policy</li>
            </Link>
            <Link to="about">
              <li className="list-group-item">about</li>
            </Link>
          </ul>
        </div>
      </footer>
  );
}

export default Footer;
