import React from 'react';
import { Route, IndexRoute } from 'react-router';
import logo from './logo.svg';
import './App.css';

function About() {
  return (
    <section>
      <div class="card" >
        <div class="card-body">
          <h5 class="card-title">About</h5>
          <h6 class="card-subtitle mb-2 text-muted">ne1Message</h6>
          <p class="card-text">This is a social instant messaging application.
          Here you have the benefits of a social network, but however with complete privacy.
          You can only communicate with contacts you have added, or contacts that have added you. 
          To add a contact you must generate a secret code on the application and give it to whoever you want to add, when the person receives the code, they will submit that code and the connection will be made. 
          Note an auto generated name is given to both parties, so it is strongly advised that you rename contacts immediately after new contact success for personal identification.
          This also means that your contacts are identified differently to any other user that is also in their contacts; this approach is one of our foundation in user privacy.
          No one can search for you or add you on the network without gaining access to a secret code you generated. 
          All secret code are valid for 24 hours. This messaging systems enables strong privacy as all atomic user identifier is abstracted away from the client. 
          </p>
          <p> Only the last 20 messages are shown for each conversation. You cannot delete a message, however you are able to delete contacts.
            If you delete any of your contacts or terminate your account, all messages will be deleted for whosoever you had conversations with.
          </p>
        </div>
      </div>
    </section>
  );
}

export default About;
