import React from 'react';
// import { Route, IndexRoute } from 'react-router';
import logo from './logo.svg';
import './App.css';
import Footer from './Footer';
import PrivacyPolicy from './PrivacyPolicy';
import About from './About';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';

class App extends React.Component {
  state = {
    redirect: false
  }

  constructor(props) {
    super(props);
    
  }

  componentDidMount() {
    this.id = setTimeout(() => this.setState({ redirect: true }), 6000)
  }

  componentWillUnmount() {
    clearTimeout(this.id)
  }
  
  render() {
    var red = this.state.redirect;
    if (red){
      console.log( "state: " + red);
      return (
        <Router>
          <div className="App">
          <Switch>
            <Route path="/" exact component={Home}/>
            <Route path="/privacy-policy" component={PrivacyPolicy}/>
            <Route path="/about" component={About}/>
          </Switch>
          <Footer/>
          </div>
          </Router> 
      );
    }else{
      console.log("state" + red);
      return(
        <Router>
          <div className="App">
          <Switch>
            <Route path="/" exact component={Animation}/>
            <Route path="/privacy-policy" component={PrivacyPolicy}/>
            <Route path="/about" component={About}/>
          </Switch>
          <Footer/>
          </div>
      </Router> 
      );
    }
  }
  
}

const Home = () => (
  
  <div className="section-logo" >
      <img alt='ne1message' src='ne1message_transparent_crop.png' style={{height: "200px"}} />
    <p>
      Download in the Google play store. Coming soon to Apple Store!
    </p>
    <ul id ="app-store">
      <li >
        <a href='https://play.google.com/store/apps/details?id=com.ne1.ne1message&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png' style={{height: "60px"}}/></a>
      </li>
      <li>
        <a ><img alt='Get it on App Store' src='Download_on_the_App_Store_Badge_US-UK_wht_092917.png' style={{height: "40px"}} /></a>
      </li>
    </ul>
  </div>
);

const Animation = () => (
  
  <div className="section-logo">
        <canvas id="gl-canvas" width="300" height="300">
          Oops ... your browser doesn't support the HTML5 canvas element
        </canvas>
    <p>
      Download in the Google play store. Coming soon to Apple Store!
    </p>
    <ul id ="app-store">
      <li >
        <a href='https://play.google.com/store/apps/details?id=com.ne1.ne1message&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png' style={{height: "60px"}}/></a>
      </li>
      <li>
        <a ><img alt='Get it on App Store' src='Download_on_the_App_Store_Badge_US-UK_wht_092917.png' style={{height: "40px"}} /></a>
      </li>
    </ul>
  </div>
);

export default App;
